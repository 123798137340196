<template>
  <DelayHydration>
    <component
      :is="frontendCeLayout"
      :uid="uid"
      :index="index"
      :appearance="appearance"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-link="headerLink"
      :header-size="headerSize"
      :bodytext="bodytext"
      :label="label"
      :item-list="itemList"
      :checkbox-nested="checkboxNested"
      :theme-color="theme"
      :lang="currentLocale"
      class="t3-ce-product-boxes"
    />
  </DelayHydration>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

import { useCurrentLocale, useLabel } from '#imports'

import type { T3CeProductBoxesProps } from '~/types'

const props = withDefaults(defineProps<T3CeProductBoxesProps>(), {
  header: '',
  subheader: '',
  headerLayout: 1,
  headerSize: 1,
  headerPosition: '',
  headerLink: '',
  bodytext: '',
  theme: 'default',
  checkboxNested: false
})

const currentLocale = useCurrentLocale()
const label = useLabel(props)
const itemList = computed(() => {
  if (!Array.isArray(props.items)) return []
  return props.items.map(({ id, content }) => ({ id, ...content }))
})

const CheckList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/CheckList')
)
const PeopleList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/PeopleList')
)
const ExtensionList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/ExtensionList')
)
const TextIconTiles = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/TextIconTiles')
)
const IconTextList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/IconTextList')
)
const ProductImageTiles = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/ProductImageTiles')
)
const ProductIconTiles = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/ProductIconTiles')
)
const ProductDescriptionList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/ProductDescriptionList')
)
const ProductDescriptionGrid = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/ProductDescriptionGrid')
)
const ThumbnailVideoList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/ThumbnailVideoList')
)
const ProductListTile = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/ProductListTile')
)
const FeedbackSlider = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/FeedbackSlider')
)
const FeaturedCaseStudySlider = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/FeaturedCaseStudySlider')
)
const Accordion = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/Accordion')
)
const Timeline = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/Timeline')
)
const StepList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/StepList')
)
const StepDiagram = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/StepDiagram')
)
const NumberList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/NumberList')
)
const HighlightedList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/HighlightedList')
)
const FeatureList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/FeatureList')
)
const FeatureListSmall = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/FeatureListSmall')
)
const MultiTextImageBanner = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/MultiTextImageBanner')
)
const FactsInNumbers = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/FactsInNumbers')
)
const TestimonialSlider = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/TestimonialSlider')
)
const TextImageTileGrid = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/TextImageTileGrid')
)
const StepGrid = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/StepGrid')
)
const TwoColumnsCheckList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/TwoColumnsCheckList')
)
const FAQ = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/FAQ')
)
const TextImageTileSlider = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/TextImageTileSlider')
)
const TextWithDiagram = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/TextWithDiagram')
)
const CompareDiagram = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/CompareDiagram')
)
const IconList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeProductBoxes/IconList')
)

const frontendCeLayout = computed(() => {
  switch (props.appearance?.layout) {
    case 'peopleList':
      return PeopleList
    case 'extensionList':
      return ExtensionList
    case 'textIconTiles':
      return TextIconTiles
    case 'iconTextList':
      return IconTextList
    case 'productImageTiles':
      return ProductImageTiles
    case 'productIconTiles':
      return ProductIconTiles
    case 'productDescriptionList':
      return ProductDescriptionList
    case 'productDescriptionGrid':
      return ProductDescriptionGrid
    case 'thumbnailVideoList':
      return ThumbnailVideoList
    case 'productListTile':
      return ProductListTile
    case 'feedbackSlider':
      return FeedbackSlider
    case 'featuredCaseStudySlider':
      return FeaturedCaseStudySlider
    case 'accordion':
      return Accordion
    case 'timeline':
      return Timeline
    case 'stepList':
      return StepList
    case 'stepDiagram':
      return StepDiagram
    case 'numberList':
      return NumberList
    case 'highlightedList':
      return HighlightedList
    case 'featureList':
      return FeatureList
    case 'featureListSmall':
      return FeatureListSmall
    case 'multiTextImageBanner':
      return MultiTextImageBanner
    case 'factsInNumbers':
      return FactsInNumbers
    // case 'factsInNumbersBig': // TODO: Remove from typo3 panel
    //   return FactsInNumbersBig
    case 'testimonialSlider':
      return TestimonialSlider
    case 'textImageTileGrid':
      return TextImageTileGrid
    case 'stepGrid':
      return StepGrid
    case 'twoColumnsCheckList':
      return TwoColumnsCheckList
    case 'faq':
      return FAQ
    case 'textImageTileSlider':
      return TextImageTileSlider
    case 'textWithDiagram':
      return TextWithDiagram
    case 'compareDiagram':
      return CompareDiagram
    case 'iconList':
      return IconList
    default:
      return CheckList
  }
})
</script>
